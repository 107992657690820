import { AppComponent } from "./app.component";
import { AboutComponent } from "./about/about.component";
import { ContactComponent } from "./contact/contact.component";
import { Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { ReferanceComponent } from "./referance/referance.component";
import { ReferanceDetailComponent } from './referance/referance-detail/referance-detail.component'
import { JobsComponent } from "./jobs/jobs.component";
import { CozumComponent } from "./cozum/cozum.component";
import { JobsDetailComponent } from './jobs/jobs-detail/jobs-detail.component';

export const appRoutes: Routes = [
  { path: "Anasayfa", component: HomeComponent },
  { path: "Hakkimizda", component: AboutComponent },
  { path: "Iletisim", component: ContactComponent },
  { path: "Referanslar", component: ReferanceComponent },
  { path: "ReferansDetay/:id", component:ReferanceDetailComponent},
  { path: "Hizmetlerimiz", component: JobsComponent },
  { path: "Hizmetlerimiz/:id", component:JobsDetailComponent},
  { path: "ÇözümOrtaklarimiz", component: CozumComponent },
  { path: "**", redirectTo: "Anasayfa", pathMatch: "full" }
];

export class Route {}
