import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
//import { FontAwesomeModule } from '@fontawesome/angular-fontawesome';
import { MatCardModule } from "@angular/material/card";
import { AngularFontAwesomeModule } from "angular-font-awesome";
import { MatButtonModule } from "@angular/material/button";
import { NgxGalleryModule } from "ngx-gallery";
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppComponent } from "./app.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { AboutComponent } from "./about/about.component";
import { ReferanceComponent } from "./referance/referance.component";
import { ReferanceDetailComponent } from "./referance/referance-detail/referance-detail.component";
import { ContactComponent } from "./contact/contact.component";
import { RouterModule } from "@angular/router";
import { appRoutes } from "./routes";
import { HomeComponent } from "./home/home.component";
import { JobsComponent } from "./jobs/jobs.component";
import { CozumComponent } from "./cozum/cozum.component";
import { FooterComponent } from "./footer/footer.component";

import "hammerjs";
import { JobsDetailComponent } from "./jobs/jobs-detail/jobs-detail.component";

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    AboutComponent,
    ReferanceComponent,
    ReferanceDetailComponent,
    ContactComponent,
    HomeComponent,
    JobsComponent,
    JobsDetailComponent,
    CozumComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    BrowserAnimationsModule,
    //FontAwesomeModule,
    AngularFontAwesomeModule,
    MatCardModule,
    MatButtonModule,
    NgxGalleryModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule {}
