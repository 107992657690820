import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-referance",
  templateUrl: "./referance.component.html",
  styleUrls: ["./referance.component.css"]
})
export class ReferanceComponent implements OnInit {
  constructor() {}

  envanter: any[] = [];

  ngOnInit() {
    this.envanter = [
      {
        id: 1,
        name: "365 AVM",
        subtitle: "ANKARA 365 AVM TERAS KAT SU YALITIMI",
        photo: "../../assets/ref/365AVM-main.jpeg",
        description: "KÖSTER KB POX 002 KÖSTER KB PUR 214 KÖSTER KUM MO 30"
      },
      {
        id: 2,
        name: "ALTER ZARF KAĞIT A.Ş.",
        subtitle: "İVEDİK ORGANİZE SANAYİ FABRİKA İNŞAATI TERAS SU YALITIMI",
        photo: "../../assets/ref/alter-zarf-kapak-main.jpg",
        description: "KÖSTER KB FİX KÖSTER FLEX BANT KÖSTER POLİFLEX 2K"
      },
      {
        id: 3,
        name: "10KAR İNŞAAT LTD ŞTİ",
        subtitle: "AMASYA SULUOVA DEVLET HASTANESİ TERAS SU YALITIMI BİTUFA & CANPA & BEŞEN ",
        photo: "../../assets/ref/10KAR-main.png",
        description: "FLEXOBİT FLEXOWALL"
      },
      {
        id: 4,
        name: "ANTALYA SERİK BELEDİYESİ",
        subtitle: "TERAS SU YALITIMI",
        photo: "../../assets/ref/antalya-serik-bel-main.jpg",
        description: "KÖSTER KB POX 002 KÖSTER KB PUR 214"
      },
      {
        id: 5,
        name: "BALIKESİR BURHANİYE DEVLET HASTAHANESİ",
        subtitle: "TERAS SU YALITIMI BİTUFA & CANPA & BEŞEN",
        photo: "../../assets/ref/balikesir-burhaniye-main.png",
        description: "FLEXOBİT FLEXOWALL"
      },
      {
        id: 6,
        name: "BURSA GEMLİK DEVLET HASTAHANESİ",
        subtitle: "TERAS SU YALITIMI BİTUFA & CANPA & BEŞEN",
        photo: "../../assets/ref/bursa-gemlik-main.png",
        description: "FLEXOBİT FLEXOWALL"
      },
      {
        id: 7,
        name: "TETİŞ YAPI İNŞAAT TİCARET A.Ş.",
        subtitle: "BURSA KESTEL DEVLET HASTAHANESİ BİTUFA & CANPA & BEŞEN",
        photo: "../../assets/ref/bursa-kestel-main.png",
        description: "FLEXOBİT FLEXOWALL"
      },
      {
        id: 8,
        name: "TEFİROM GRUP İNŞAAT ENERJİ SAN. VE TİC. A.Ş.",
        subtitle: "KÜTAHYA DUMLUPINAR ÜNİVERSİTESİ YURT ŞANTİYESİ TEMEL SU YALITIMI ",
        photo: "../../assets/ref/dumlupınar-üni-yurt-main.jpg",
        description: "FLEETWİCK MEMBRAN"
      },
      {
        id: 9,
        name: "HEKTAŞ GRUP",
        subtitle: "ERZURUM İL SAĞLIK PROJESİ TERAS SU YALITIMI BİTUFA & CANPA & BEŞEN",
        photo: "../../assets/ref/erzurum-il-saglik-main.jpg",
        description: "FLEXOBİT FLEXOWALL"
      },
      {
        id: 10,
        name: "EKSEN İNŞAAT A.Ş.",
        subtitle: "GİRESUN ÇAMOLUK KOÇAK HES PROJESİ",
        photo: "../../assets/ref/giresun-hes-main.jpg",
        description: "KÖSTER DEUXAN 2K"
      },
      {
        id: 11,
        name: "RÖNENSANS HOLDİNG",
        subtitle: "İZMİR GÖZTEPE STADI BİTUFA & CANPA & BEŞEN",
        photo: "../../assets/ref/göztepe-stadi-main.jpg",
        description: "FLEXOBİT FLEXOWALL"
      },
      {
        id: 12,
        name: "İNANÇ İNŞAAT",
        subtitle: "ANKARA PARK CADDESİ GREEN FLAT PROJESİ PERDE SU YALITIMI",
        photo: "../../assets/ref/green-flat-main.jpg",
        description: "KÖSTER DEUXAN 2K"
      },
      {
        id: 13,
        name: "ALİ SERT OTELCİLİK",
        subtitle: "KAYSERİ HOLİDAY İNN PROJESİ TEMEL & TERAS SU YALITIMI",
        photo: "../../assets/ref/kayseri-holiday-inn-main.jpg",
        description: "KÖSTER DEUXAN 2K BTM ELASTOBİT PE3"
      },
      {
        id: 14,
        name: "MAPİN İNŞAAT",
        subtitle: "İNCEK OASİSİ APART PROJESİ SÜS HAVUZU YALITIMI",
        photo: "../../assets/ref/incek-oasisi-main.jpg",
        description: "KÖSTER REPAMOR KÖSTER NB SİSTEM"
      },
      {
        id: 15,
        name: "KASVA İNŞAAT LTD. ŞTİ.",
        subtitle: "ANKARA ÇAKIRLAR STAR KONUTLARI PROJESİ BODRUM PERDE SU & ISI YALITIMI",
        photo: "../../assets/ref/cakirlar-star-konutlari-main.jpg",
        description: "KÖSTER REPAMOR KÖSTER DEUXAN 2K KÖSTER FLEX BANT"
      },
      {
        id: 16,
        name: "KASVA İNŞAAT LTD. ŞTİ.",
        subtitle: "ANKARA ÇAKIRLAR STAR KONUTLARI TERAS SU YALITIMI",
        photo: "../../assets/ref/cakirlar-star-konutlari-main.jpg",
        description: "KÖSTER DEUXAN 2K KÖSTER NB ELASTİK 2K"
      },
      {
        id: 17,
        name: "TÜRKSEVEN İNŞAAT A.Ş.",
        subtitle: "KEÇİÖREN BELEDİYESİ OTOPARK ŞANTİYESİ",
        photo: "../../assets/ref/kecioren-bel-main.jpg",
        description: "İNKA KAPFILL"
      },
      {
        id: 18,
        name: "HÜSAMETTİN PEKER İNŞAAT",
        subtitle: "MANİSA MERKEZEFENDİ 400 YATAKLI DEVLET HASTAHANESİ BİTUFA & CANPA & BEŞEN",
        photo: "../../assets/ref/manisa-merkezefendi-main.jpeg",
        description: "FLEXOBİT FLEXOWALL"
      },
      {
        id: 19,
        name: "MİM YAPI LTD. ŞTİ.",
        subtitle: "ANKARA MEMUR SEN GENEL MERKEZ BİNASI BODRUM KAT POLİÜRETAN ENJEKSİYON YALITIMI",
        photo: "../../assets/ref/memur-sen-main.jpg",
        description: "KÖSTER SUPERPACKER KÖSTER KB PUR İN 7"
      },
      {
        id: 20,
        name: "MURADİYE VAKFI",
        subtitle: "ERYAMAN SEVGİ KOLEJİ TERAS SU YALITIMI",
        photo: "../../assets/ref/eryaman-sevgi-koleji-main.jpg",
        description: "KÖSTER DEUXAN 2K"
      },
      {
        id: 21,
        name: "MURADİYE VAKFI",
        subtitle: "ANKARA İNCEK SEVGİ KOLEJİ TERAS SU YALITIMI",
        photo: "../../assets/ref/incek-sevgi-koleji-main.jpg",
        description: "KÖSTER DEUXAN 2K"
      },
      {
        id: 22,
        name: "MURADİYE VAKFI",
        subtitle: "ANKARA PURSAKLAR NENE HATUN KOLEJİ TERAS SU YALITIMI",
        photo: "../../assets/ref/pursaklar-nene-hatun-koleji-main.jpg",
        description: "KÖSTER REPAMOR KÖSTER DEUXAN 2K"
      },
      {
        id: 23,
        name: "BAHADIR MÜHENDİSLİK DOĞAN KARATAŞ İNŞAAT",
        subtitle: "NEVŞEHİR DEVLET HASTANESİ TERAS SU YALITIMI BİTUFA & CANPA & BEŞEN",
        photo: "../../assets/ref/nevsehir-devlet-hastanesi-main.jpg",
        description: "FLEXOBİT FLEXOWALL"
      },
      {
        id: 24,
        name: "YENİ AKDAĞ TEKNİK YALITIM LTD ŞTİ",
        subtitle: "MANAVGAT NOVAMALL AVM TERAS DİLATASYON SU YALITIMI",
        photo: "../../assets/ref/novamall-avm-main.jpg",
        description: "KÖSTER FUGENSPACHTEL FS-H KÖSTER POX PRİMER 2K KÖSTER KB FİX KÖSTER FLEX BANT"
      },
      {
        id: 25,
        name: "PİFA İNŞAAT LTD ŞTİ",
        subtitle: "ANKARA ALTINDAĞ PAZAR YERİ PREKAST ÇATI DERESİ VE TERAS SU YALITIMI",
        photo: "../../assets/ref/altındağ-pazar-yeri-main.jpg",
        description: "KÖSTER KBE FLÜSSİGFOLİE MEMBRAN BALCIOĞLU STANDART"
      },
      {
        id: 26,
        name: "TOKER MESKEN GAYRİMENKUL A.Ş",
        subtitle: "ANKARA PLATİN BEYTEPE KONUTLARI TERAS SU YALITIMI",
        photo: "../../assets/ref/beytepe-konutları-main.jpg",
        description: "KÖSTER KB FİX KÖSTER FLEX BANT KÖSTER DEUXAN 2K MEMBRAN MİDA TROPİK"
      }
      
      
    ];
  }
}
