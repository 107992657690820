import { Component, OnInit, HostListener } from "@angular/core";
import { elementEventFullName } from "@angular/compiler/src/view_compiler/view_compiler";
import * as $ from "jquery";

@Component({
  selector: "app-jobs",
  templateUrl: "./jobs.component.html",
  styleUrls: ["./jobs.component.css"]
})
export class JobsComponent implements OnInit {
  title = "Look jQuery Animation working in action!";
  imageSource: string;
  constructor() {}

  ngOnInit() {

    $(document).ready(function() {
      $("#yuvarlak1").mouseover(function() {
        $("#yuvarlak1").css({ top: "15%" });
        $("#yuvarlak1").css({ left: "15%" });
        $("#yuvarlak1").css({ height: "50%" });
        $("#yuvarlak1").css({ width: "50%" });
        $("#yuvarlak1").css({ "z-index":"1" });
        $("#yuvarlak1").css({ opacity: "1" });
        $("#yuvarlak1").css({"cursor":"pointer"});
      });

      $("#yuvarlak1").mouseout(function() {
        $("#yuvarlak1").css({  top: "34.6%"});
        $("#yuvarlak1").css({ left: "29.8%" });
        $("#yuvarlak1").css({ height: "1px" });
        $("#yuvarlak1").css({ width: "1px" });
        $("#yuvarlak1").css({ "z-index":"0" });
        $("#yuvarlak1").css({ opacity: "0.1" });
      })


      $("#yuvarlak2").mouseover(function() {
        $("#yuvarlak2").css({ top: "25%" });
        $("#yuvarlak2").css({ left: "11%" });
        $("#yuvarlak2").css({ height: "50%" });
        $("#yuvarlak2").css({ width: "50%" });
        $("#yuvarlak2").css({ "z-index":"1" });
        $("#yuvarlak2").css({ opacity: "1" });
        $("#yuvarlak2").css({"cursor":"pointer"});
      });

      $("#yuvarlak2").mouseout(function() {
        $("#yuvarlak2").css({  top: "40.5%"});
        $("#yuvarlak2").css({ left: "26%" });
        $("#yuvarlak2").css({ height: "1px" });
        $("#yuvarlak2").css({ width: "1px" });
        $("#yuvarlak2").css({ "z-index":"0" });
        $("#yuvarlak2").css({ opacity: "0.1" });
      })

      $("#yuvarlak3").mouseover(function() {
        $("#yuvarlak3").css({ top: "35%" });
        $("#yuvarlak3").css({ left: "40%" });
        $("#yuvarlak3").css({ height: "50%" });
        $("#yuvarlak3").css({ width: "50%" });
        $("#yuvarlak3").css({ "z-index":"1" });
        $("#yuvarlak3").css({ opacity: "1" });
        $("#yuvarlak3").css({"cursor":"pointer"});
      });

      $("#yuvarlak3").mouseout(function() {
        $("#yuvarlak3").css({  top: "45.9%"});
        $("#yuvarlak3").css({ left: "54.6%" });
        $("#yuvarlak3").css({ height: "1px" });
        $("#yuvarlak3").css({ width: "1px" });
        $("#yuvarlak3").css({ "z-index":"0" });
        $("#yuvarlak3").css({ opacity: "0.1" });
      })

      $("#yuvarlak4").mouseover(function() {
        $("#yuvarlak4").css({ top: "40%" });
        $("#yuvarlak4").css({ left: "40%" });
        $("#yuvarlak4").css({ height: "50%" });
        $("#yuvarlak4").css({ width: "50%" });
        $("#yuvarlak4").css({ "z-index":"1" });
        $("#yuvarlak4").css({ opacity: "1" });
        $("#yuvarlak4").css({"cursor":"pointer"});
      });

      $("#yuvarlak4").mouseout(function() {
        $("#yuvarlak4").css({  top: "51.2%"});
        $("#yuvarlak4").css({ left: "47.9%" });
        $("#yuvarlak4").css({ height: "1px" });
        $("#yuvarlak4").css({ width: "1px" });
        $("#yuvarlak4").css({ "z-index":"0" });
        $("#yuvarlak4").css({ opacity: "0.1" });
      })

      $("#yuvarlak5").mouseover(function() {
        $("#yuvarlak5").css({ top: "45%" });
        $("#yuvarlak5").css({ left: "30%" });
        $("#yuvarlak5").css({ height: "50%" });
        $("#yuvarlak5").css({ width: "50%" });
        $("#yuvarlak5").css({ "z-index":"1" });
        $("#yuvarlak5").css({ opacity: "1" });
        $("#yuvarlak5").css({"cursor":"pointer"});
      });

      $("#yuvarlak5").mouseout(function() {
        $("#yuvarlak5").css({  top: "56.2%"});
        $("#yuvarlak5").css({ left: "39.1%" });
        $("#yuvarlak5").css({ height: "1px" });
        $("#yuvarlak5").css({ width: "1px" });
        $("#yuvarlak5").css({ "z-index":"0" });
        $("#yuvarlak5").css({ opacity: "0.1" });
      })

      $("#yuvarlak6").mouseover(function() {
        $("#yuvarlak6").css({ top: "35%" });
        $("#yuvarlak6").css({ left: "40%" });
        $("#yuvarlak6").css({ height: "50%" });
        $("#yuvarlak6").css({ width: "50%" });
        $("#yuvarlak6").css({ "z-index":"1" });
        $("#yuvarlak6").css({ opacity: "6" });
        $("#yuvarlak6").css({"cursor":"pointer"});
      });

      $("#yuvarlak6").mouseout(function() {
        $("#yuvarlak6").css({  top: "62%"});
        $("#yuvarlak6").css({ left: "48%" });
        $("#yuvarlak6").css({ height: "1px" });
        $("#yuvarlak6").css({ width: "1px" });
        $("#yuvarlak6").css({ "z-index":"0" });
        $("#yuvarlak6").css({ opacity: "0.1" });
      })

      $("#yuvarlak7").mouseover(function() {
        $("#yuvarlak7").css({ top: "60%" });
        $("#yuvarlak7").css({ left: "40%" });
        $("#yuvarlak7").css({ height: "50%" });
        $("#yuvarlak7").css({ width: "50%" });
        $("#yuvarlak7").css({ "z-index":"1" });
        $("#yuvarlak7").css({ opacity: "1" });
        $("#yuvarlak7").css({"cursor":"pointer"});
      });

      $("#yuvarlak7").mouseout(function() {
        $("#yuvarlak7").css({  top: "67%"});
        $("#yuvarlak7").css({ left: "48.5%" });
        $("#yuvarlak7").css({ height: "1px" });
        $("#yuvarlak7").css({ width: "1px" });
        $("#yuvarlak7").css({ "z-index":"0" });
        $("#yuvarlak7").css({ opacity: "0.1" });
      })

      $("#yuvarlak8").mouseover(function() {
        $("#yuvarlak8").css({ top: "60%" });
        $("#yuvarlak8").css({ left: "50%" });
        $("#yuvarlak8").css({ height: "50%" });
        $("#yuvarlak8").css({ width: "50%" });
        $("#yuvarlak8").css({ "z-index":"1" });
        $("#yuvarlak8").css({ opacity: "1" });
        $("#yuvarlak8").css({"cursor":"pointer"});
      });

      $("#yuvarlak8").mouseout(function() {
        $("#yuvarlak8").css({  top: "72.5%"});
        $("#yuvarlak8").css({ left: "57.8%" });
        $("#yuvarlak8").css({ height: "1px" });
        $("#yuvarlak8").css({ width: "1px" });
        $("#yuvarlak8").css({ "z-index":"0" });
        $("#yuvarlak8").css({ opacity: "0.1" });
      })

      $("#yuvarlak9").mouseover(function() {
        $("#yuvarlak9").css({ top: "65%" });
        $("#yuvarlak9").css({ left: "55%" });
        $("#yuvarlak9").css({ height: "50%" });
        $("#yuvarlak9").css({ width: "50%" });
        $("#yuvarlak9").css({ "z-index":"1" });
        $("#yuvarlak9").css({ opacity: "1" });
        $("#yuvarlak9").css({"cursor":"pointer"});
      });

      $("#yuvarlak9").mouseout(function() {
        $("#yuvarlak9").css({  top: "75.9%"});
        $("#yuvarlak9").css({ left: "62.7%" });
        $("#yuvarlak9").css({ height: "1px" });
        $("#yuvarlak9").css({ width: "1px" });
        $("#yuvarlak9").css({ "z-index":"0" });
        $("#yuvarlak9").css({ opacity: "0.1" });
      });

      $("#yuvarlak10").mouseover(function() {
        $("#yuvarlak10").css({ top: "60%" });
        $("#yuvarlak10").css({ left: "55%" });
        $("#yuvarlak10").css({ height: "50%" });
        $("#yuvarlak10").css({ width: "50%" });
        $("#yuvarlak10").css({ "z-index":"1" });
        $("#yuvarlak10").css({ opacity: "1" });
        $("#yuvarlak10").css({"cursor":"pointer"});
      });

      $("#yuvarlak10").mouseout(function() {
        $("#yuvarlak10").css({  top: "68.4%"});
        $("#yuvarlak10").css({ left: "63.2%" });
        $("#yuvarlak10").css({ height: "1px" });
        $("#yuvarlak10").css({ width: "1px" });
        $("#yuvarlak10").css({ "z-index":"0" });
        $("#yuvarlak10").css({ opacity: "0.1" });
      });

      $("#yuvarlak11").mouseover(function() {
        $("#yuvarlak11").css({ top: "50%" });
        $("#yuvarlak11").css({ left: "60%" });
        $("#yuvarlak11").css({ height: "50%" });
        $("#yuvarlak11").css({ width: "50%" });
        $("#yuvarlak11").css({ "z-index":"1" });
        $("#yuvarlak11").css({ opacity: "1" });
        $("#yuvarlak11").css({"cursor":"pointer"});
      });

      $("#yuvarlak11").mouseout(function() {
        $("#yuvarlak11").css({  top: "61.6%"});
        $("#yuvarlak11").css({ left: "67.2%" });
        $("#yuvarlak11").css({ height: "1px" });
        $("#yuvarlak11").css({ width: "1px" });
        $("#yuvarlak11").css({ "z-index":"0" });
        $("#yuvarlak11").css({ opacity: "0.1" });
      });

      $("#yuvarlak12").mouseover(function() {
        $("#yuvarlak12").css({ top: "45%" });
        $("#yuvarlak12").css({ left: "60%" });
        $("#yuvarlak12").css({ height: "50%" });
        $("#yuvarlak12").css({ width: "50%" });
        $("#yuvarlak12").css({ "z-index":"1" });
        $("#yuvarlak12").css({ opacity: "1" });
        $("#yuvarlak12").css({"cursor":"pointer"});
      });

      $("#yuvarlak12").mouseout(function() {
        $("#yuvarlak12").css({  top: "54.4%"});
        $("#yuvarlak12").css({ left: "68.6%" });
        $("#yuvarlak12").css({ height: "1px" });
        $("#yuvarlak12").css({ width: "1px" });
        $("#yuvarlak12").css({ "z-index":"0" });
        $("#yuvarlak12").css({ opacity: "0.1" });
      });

      $("#yuvarlak13").mouseover(function() {
        $("#yuvarlak13").css({ top: "40%" });
        $("#yuvarlak13").css({ left: "50%" });
        $("#yuvarlak13").css({ height: "50%" });
        $("#yuvarlak13").css({ width: "50%" });
        $("#yuvarlak13").css({ "z-index":"1" });
        $("#yuvarlak13").css({ opacity: "1" });
        $("#yuvarlak13").css({"cursor":"pointer"});
      });

      $("#yuvarlak13").mouseout(function() {
        $("#yuvarlak13").css({  top: "48.6%"});
        $("#yuvarlak13").css({ left: "59%" });
        $("#yuvarlak13").css({ height: "1px" });
        $("#yuvarlak13").css({ width: "1px" });
        $("#yuvarlak13").css({ "z-index":"0" });
        $("#yuvarlak13").css({ opacity: "0.1" });
      });

      $("#yuvarlak14").mouseover(function() {
        $("#yuvarlak14").css({ top: "35%" });
        $("#yuvarlak14").css({ left: "50%" });
        $("#yuvarlak14").css({ height: "50%" });
        $("#yuvarlak14").css({ width: "50%" });
        $("#yuvarlak14").css({ "z-index":"1" });
        $("#yuvarlak14").css({ opacity: "1" });
        $("#yuvarlak14").css({"cursor":"pointer"});
      });

      $("#yuvarlak14").mouseout(function() {
        $("#yuvarlak14").css({  top: "43.7%"});
        $("#yuvarlak14").css({ left: "62.2%" });
        $("#yuvarlak14").css({ height: "1px" });
        $("#yuvarlak14").css({ width: "1px" });
        $("#yuvarlak14").css({ "z-index":"0" });
        $("#yuvarlak14").css({ opacity: "0.1" });
      })

      $("#yuvarlak15").mouseover(function() {
        $("#yuvarlak15").css({ top: "30%" });
        $("#yuvarlak15").css({ left: "65%" });
        $("#yuvarlak15").css({ height: "50%" });
        $("#yuvarlak15").css({ width: "50%" });
        $("#yuvarlak15").css({ "z-index":"1" });
        $("#yuvarlak15").css({ opacity: "1" });
        $("#yuvarlak15").css({"cursor":"pointer"});
      });

      $("#yuvarlak15").mouseout(function() {
        $("#yuvarlak15").css({  top: "38.4%"});
        $("#yuvarlak15").css({ left: "73.2%" });
        $("#yuvarlak15").css({ height: "1px" });
        $("#yuvarlak15").css({ width: "1px" });
        $("#yuvarlak15").css({ "z-index":"0" });
        $("#yuvarlak15").css({ opacity: "0.1" });
      })
    });

    /*$(document).ready(function() {
      $("map[name=Map0] area").mouseover(function() {
        id = $(this).attr("id");
        $("#uygulama" + id).fadeIn(500);
      });

      $(".yuvarlaklar").mouseout(function() {
        id = $(this).attr("id");
        $("#" + id).fadeOut(300);
      });
    });*/
  }
}
