import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-jobs-detail",
  templateUrl: "./jobs-detail.component.html",
  styleUrls: ["./jobs-detail.component.css"]
})
export class JobsDetailComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute) {}

  photoNumber: number;
  hizmetlerPhotos: any[];

  ngOnInit() {
    this.activatedRoute.params.subscribe(res => {
      this.photoNumber = res.id;
      //console.log(res.id);
      //console.log(this.photoNumber);
    });

    this.hizmetlerPhotos = [
      { id: 1, url: "../../../assets/img/cati.jpg" },
      { id: 2, url: "../../../assets/img/havuz-izolasyon.jpg" },
      { id: 3, url: "../../../assets/img/teras-izolasyonu.jpg" },
      { id: 4, url: "../../../assets/img/cephe-isi.jpg" },
      { id: 5, url: "../../../assets/img/su-deposu.jpg" },
      { id: 6, url: "../../../assets/img/yatay-dilatasyon.jpg" },
      { id: 7, url: "../../../assets/img/kazikbasi.jpg" },
      { id: 8, url: "../../../assets/img/islak-hacim-main.jpg" },
      { id: 9, url: "../../../assets/img/temel-perde.jpg" },
      { id: 10, url: "../../../assets/img/negatif.jpg" },
      { id: 11, url: "../../../assets/img/basincli-su.png" },
      { id: 12, url: "../../../assets/img/kalip-ayirici.jpg" },
      { id: 13, url: "../../../assets/img/zemin-uygulama-yardimcilari.jpg" },
      { id: 14, url: "../../../assets/img/epoksi-kaplama.jpg" },
      { id: 15, url: "../../../assets/img/cephe-kaplama.jpg" }
    ];
  }
}
