import { Component, OnInit, ElementRef } from "@angular/core";
import * as $ from "jquery";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"]
})
export class NavbarComponent implements OnInit {
  private toggleButton: any;
  private sidebarVisible: boolean;

  constructor(private element: ElementRef) {
    //public location: Location,
    this.sidebarVisible = false;
  }

  ngOnInit() {
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];

    $(document).scroll(function MyFunc(e) {
      var y = $(window).scrollTop();
      if (y > 150) {
        $(".navbar-transparent").css("background-color", "#D2232A");
      }
      else{
        $(".navbar-transparent").css("background-color", "transparent");
      }
    });
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName("html")[0];
    setTimeout(function() {
      toggleButton.classList.add("toggled");
    }, 500);
    html.classList.add("nav-open");

    this.sidebarVisible = true;
  }
  sidebarClose() {
    const html = document.getElementsByTagName("html")[0];
    // console.log(html);
    this.toggleButton.classList.remove("toggled");
    this.sidebarVisible = false;
    html.classList.remove("nav-open");
  }
  sidebarToggle() {
    // const toggleButton = this.toggleButton;
    // const body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  // isDocumentation() {
  //     var titlee = this.location.prepareExternalUrl(this.location.path());
  //     if( titlee === '/documentation' ) {
  //         return true;
  //     }
  //     else {
  //         return false;
  //     }
  // }
}
