import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  NgxGalleryOptions,
  NgxGalleryImage,
  NgxGalleryAnimation
} from "ngx-gallery";

@Component({
  selector: "app-referance-detail",
  templateUrl: "./referance-detail.component.html",
  styleUrls: ["./referance-detail.component.css"]
})
export class ReferanceDetailComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute) {}

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  detail2id: any;
  photo361: any;
  envanter = [
    {
      id: 1,
      name: "365 AVM",
      //subtitle: "Referans ismi alt başlık2",
      photo: [
        "../../assets/ref/365AVM-photo1.jpeg",
        "../../assets/ref/365AVM-photo2.jpeg",
        "../../assets/ref/365AVM-photo3.jpeg",
        "../../assets/ref/365AVM-photo4.jpeg"
      ],
      //description: "Acıklama bu alana gelecektir"
    },

    {
      id: 2,
      name: "Alter Zarf Kağıt A.Ş.",
      //subtitle: "İvedik Organize Sanayi Fabrika İnşaatı Teras Su Yalıtımı",
      photo: [
        "../../assets/ref/alter-zarf-photo1.jpg",
        "../../assets/ref/alter-zarf-photo2.jpg",
        "../../assets/ref/alter-zarf-photo3.jpg",
        "../../assets/ref/alter-zarf-photo4.jpg"
      ],
      description: "KÖSTER Polyflex 2K, Köster KB Fix, KÖSTER Flex Bant"
    },
    
    {
      id: 3,
      name: "Referans ismi3",
      subtitle: "Referans ismi alt başlık3",
      photo: [
        "../../assets/ref/10KAR-photo1.jpg",
        "../../assets/ref/10KAR-photo2.jpg",
        "../../assets/ref/10KAR-photo3.jpg",
        "../../assets/ref/10KAR-photo4.jpg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 4,
      name: "Referans ismi4",
      subtitle: "Referans ismi alt başlık4",
      photo: [
        "../../assets/ref/antalya-serik-bel-photo1.jpeg",
        "../../assets/ref/antalya-serik-bel-photo2.jpeg",
        "../../assets/ref/antalya-serik-bel-photo3.jpeg",
        "../../assets/ref/antalya-serik-bel-photo4.jpeg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 5,
      name: "Referans ismi5",
      subtitle: "Referans ismi alt başlık5",
      photo: [
        "../../assets/ref/balikesir-burhaniye-photo1.jpg",
        "../../assets/ref/balikesir-burhaniye-photo2.jpg",
        "../../assets/ref/balikesir-burhaniye-photo3.jpg",
        "../../assets/ref/balikesir-burhaniye-photo4.jpg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 6,
      name: "Referans ismi6",
      subtitle: "Referans ismi alt başlık6",
      photo: [
        "../../assets/ref/bursa-gemlik-photo1.jpg",
        "../../assets/ref/bursa-gemlik-photo2.jpg",
        "../../assets/ref/bursa-gemlik-photo3.jpg",
        "../../assets/ref/bursa-gemlik-photo4.jpg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 7,
      name: "Referans ismi6",
      subtitle: "Referans ismi alt başlık6",
      photo: [
        "../../assets/ref/bursa-kestel-photo1.jpg",
        "../../assets/ref/bursa-kestel-photo2.jpg",
        "../../assets/ref/bursa-kestel-photo3.jpg",
        "../../assets/ref/bursa-kestel-photo4.jpg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 8,
      name: "Referans ismi6",
      subtitle: "Referans ismi alt başlık6",
      photo: [
        "../../assets/ref/dumlupınar-üni-yurt-photo1.jpg",
        "../../assets/ref/dumlupınar-üni-yurt-photo2.jpg",
        "../../assets/ref/dumlupınar-üni-yurt-photo3.jpg",
        "../../assets/ref/dumlupınar-üni-yurt-photo4.jpg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 9,
      name: "Referans ismi6",
      subtitle: "Referans ismi alt başlık6",
      photo: [
        "../../assets/ref/erzurum-il-saglik-photo1.jpg",
        "../../assets/ref/erzurum-il-saglik-photo2.jpg",
        "../../assets/ref/erzurum-il-saglik-photo3.jpg",
        "../../assets/ref/erzurum-il-saglik-photo4.jpg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 10,
      name: "Referans ismi6",
      subtitle: "Referans ismi alt başlık6",
      photo: [
        "../../assets/ref/giresun-hes-photo1.jpg",
        "../../assets/ref/giresun-hes-photo2.jpg",
        "../../assets/ref/giresun-hes-photo3.jpg",
        "../../assets/ref/giresun-hes-photo4.jpg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 11,
      name: "Referans ismi6",
      subtitle: "Referans ismi alt başlık6",
      photo: [
        "../../assets/ref/göztepe-stadi-photo1.jpg",
        "../../assets/ref/göztepe-stadi-photo2.jpg",
        "../../assets/ref/göztepe-stadi-photo3.jpg",
        "../../assets/ref/göztepe-stadi-photo4.jpg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 12,
      name: "Referans ismi6",
      subtitle: "Referans ismi alt başlık6",
      photo: [
        "../../assets/ref/green-flat-photo1.jpg",
        "../../assets/ref/green-flat-photo2.jpg",
        "../../assets/ref/green-flat-photo3.jpg",
        "../../assets/ref/green-flat-photo4.jpg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 13,
      name: "Referans ismi6",
      subtitle: "Referans ismi alt başlık6",
      photo: [
        "../../assets/ref/kayseri-holiday-inn-photo1.jpg",
        "../../assets/ref/kayseri-holiday-inn-photo2.jpg",
        "../../assets/ref/kayseri-holiday-inn-photo3.jpg",
        "../../assets/ref/kayseri-holiday-inn-photo4.jpg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 14,
      name: "Referans ismi6",
      subtitle: "Referans ismi alt başlık6",
      photo: [
        "../../assets/ref/incek-oasisi-photo1.jpeg",
        "../../assets/ref/incek-oasisi-photo2.jpeg",
        "../../assets/ref/incek-oasisi-photo3.jpeg",
        "../../assets/ref/incek-oasisi-photo4.jpeg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 15,
      name: "Referans ismi6",
      subtitle: "Referans ismi alt başlık6",
      photo: [
        "../../assets/ref/cakirlar-star-konutlari-perde-photo1.jpeg",
        "../../assets/ref/cakirlar-star-konutlari-perde-photo2.jpeg",
        "../../assets/ref/cakirlar-star-konutlari-perde-photo3.jpeg",
        "../../assets/ref/cakirlar-star-konutlari-perde-photo4.jpeg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 16,
      name: "Referans ismi6",
      subtitle: "Referans ismi alt başlık6",
      photo: [
        "../../assets/ref/cakirlar-star-konutlari-teras-photo1.jpg",
        "../../assets/ref/cakirlar-star-konutlari-teras-photo2.jpg",
        "../../assets/ref/cakirlar-star-konutlari-teras-photo3.jpg",
        "../../assets/ref/cakirlar-star-konutlari-teras-photo4.jpg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 17,
      name: "Referans ismi6",
      subtitle: "Referans ismi alt başlık6",
      photo: [
        "../../assets/ref/kecioren-bel-photo1.jpeg",
        "../../assets/ref/kecioren-bel-photo2.jpeg",
        "../../assets/ref/kecioren-bel-photo3.jpeg",
        "../../assets/ref/kecioren-bel-photo4.jpeg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 18,
      name: "Referans ismi6",
      subtitle: "Referans ismi alt başlık6",
      photo: [
        "../../assets/ref/manisa-merkezefendi-photo1.jpg",
        "../../assets/ref/manisa-merkezefendi-photo2.jpg",
        "../../assets/ref/manisa-merkezefendi-photo3.jpg",
        "../../assets/ref/manisa-merkezefendi-photo4.jpg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 19,
      name: "Referans ismi6",
      subtitle: "Referans ismi alt başlık6",
      photo: [
        "../../assets/ref/memur-sen-photo1.jpg",
        "../../assets/ref/memur-sen-photo2.jpg",
        "../../assets/ref/memur-sen-photo3.jpg",
        "../../assets/ref/memur-sen-photo4.jpg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 20,
      name: "Referans ismi6",
      subtitle: "Referans ismi alt başlık6",
      photo: [
        "../../assets/ref/eryaman-sevgi-koleji-photo1.jpg",
        "../../assets/ref/eryaman-sevgi-koleji-photo2.jpg",
        "../../assets/ref/eryaman-sevgi-koleji-photo3.jpg",
        "../../assets/ref/eryaman-sevgi-koleji-photo4.jpg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 21,
      name: "Referans ismi6",
      subtitle: "Referans ismi alt başlık6",
      photo: [
        "../../assets/ref/incek-sevgi-koleji-photo1.jpeg",
        "../../assets/ref/incek-sevgi-koleji-photo2.jpeg",
        "../../assets/ref/incek-sevgi-koleji-photo3.jpeg",
        "../../assets/ref/incek-sevgi-koleji-photo4.jpeg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 22,
      name: "Referans ismi6",
      subtitle: "Referans ismi alt başlık6",
      photo: [
        "../../assets/ref/pursaklar-nene-hatun-koleji-photo1.jpg",
        "../../assets/ref/pursaklar-nene-hatun-koleji-photo2.jpg",
        "../../assets/ref/pursaklar-nene-hatun-koleji-photo3.jpg",
        "../../assets/ref/pursaklar-nene-hatun-koleji-photo4.jpg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 23,
      name: "Referans ismi6",
      subtitle: "Referans ismi alt başlık6",
      photo: [
        "../../assets/ref/nevsehir-devlet-hastanesi-photo1.jpg",
        "../../assets/ref/nevsehir-devlet-hastanesi-photo2.jpg",
        "../../assets/ref/nevsehir-devlet-hastanesi-photo3.jpg",
        "../../assets/ref/nevsehir-devlet-hastanesi-photo4.jpg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 24,
      name: "Referans ismi6",
      subtitle: "Referans ismi alt başlık6",
      photo: [
        "../../assets/ref/novamall-avm-photo1.jpg",
        "../../assets/ref/novamall-avm-photo2.jpg",
        "../../assets/ref/novamall-avm-photo3.jpg",
        "../../assets/ref/novamall-avm-photo4.jpg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 25,
      name: "Referans ismi6",
      subtitle: "Referans ismi alt başlık6",
      photo: [
        "../../assets/ref/altındağ-pazar-yeri-photo1.jpeg",
        "../../assets/ref/altındağ-pazar-yeri-photo2.jpeg",
        "../../assets/ref/altındağ-pazar-yeri-photo3.jpeg",
        "../../assets/ref/altındağ-pazar-yeri-photo4.jpeg"
      ],
      description: "Acıklama bu alana gelecektir"
    },
    {
      id: 26,
      name: "Referans ismi6",
      subtitle: "Referans ismi alt başlık6",
      photo: [
        "../../assets/ref/beytepe-konutları-photo1.jpeg",
        "../../assets/ref/beytepe-konutları-photo2.jpeg",
        "../../assets/ref/beytepe-konutları-photo3.jpeg",
        "../../assets/ref/beytepe-konutları-photo4.jpeg"
      ],
      description: "Acıklama bu alana gelecektir"
    }
  ];

  ngOnInit() {
    this.activatedRoute.params.subscribe(res => {
      this.detail2id = res.id;
      this.photo361 = this.envanter.find(item => item.id == this.detail2id);
    });

    this.galleryOptions = [
      {
        width: "900px",
        height: "600px",
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: "100%",
        height: "600px",
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

    this.galleryImages = [
      {
        small: this.photo361.photo[0],
        medium: this.photo361.photo[0],
        big: this.photo361.photo[0]
      },
      {
        small: this.photo361.photo[1],
        medium: this.photo361.photo[1],
        big: this.photo361.photo[1]
      },
      {
        small: this.photo361.photo[2],
        medium: this.photo361.photo[2],
        big: this.photo361.photo[2]
      },
      {
        small: this.photo361.photo[3],
        medium: this.photo361.photo[3],
        big: this.photo361.photo[3]
      }
    ];
  }
}
